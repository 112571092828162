<script>
export default {
	inject: ['manager'],
	props: {
		tagClass: String,
	},
}
</script>

<template>
	<div
		v-if="manager.tags && manager.tags.length"
		:class="['product-card__tags', { 'product-card__tags--desktop': !$b.m }]"
	>
		<div v-for="({ html, style }, n) in manager.tags.splice(0, 2)" :key="n">
			<div
				v-html="html"
				class="product-card__tag px-1 rounded line-clamp-1"
				:class="tagClass"
				:style="style"
			></div>
		</div>
	</div>
</template>

