<script>
import { get } from 'vuex-pathify'

export default {
	data() {
		return {
			products: [],
			loading: false,
			fetching: false,
			search: '',
			searchFilter: '',
			categoryOptions: [],
			loadingSearch: false,
			selectedCategory: null,
			qtyMap: {},
		}
	},
	computed: {
		order: get('cart/order'),
		filteredProducts() {
			let res = this.products

			if (this.searchFilter || this.selectedCategory) {
				res = res.filter((product) => {
					if (this.searchFilter) {
						let matched = this.searchFilter
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '')
							.split(' ')
							.filter(Boolean)
							.every((str) => product.searchString.includes(str))

						if (!matched) return false
					}

					if (this.selectedCategory && product.categories != this.selectedCategory) {
						return false
					}

					return true
				})
			}

			return (
				res?.reduce((acc, product, index) => {
					if (index === 0 || product.categories !== res[index - 1].categories) {
						acc.push({ isCategoryLabel: true, categories: product.categories })
					}

					acc.push(product)
					return acc
				}, []) || []
			)
		},
	},
	watch: {
		order: {
			handler() {
				this.setQtyMap()
			},
		},
		search(value) {
			setTimeout(() => {
				if (this.search === value) {
					this.searchFilter = value
					this.toScroll()
				}
			}, 500)
		},
	},
	methods: {
		onBlur(variant, ev) {
			const qty = ev
			const item = this.order?.items.find((item) => item.cartData.variantId == variant.id)

			if (item?.qty == qty) return

			if (!item && qty > 0) {
				this.$shop.addToCart({
					productId: variant.productId,
					variantId: variant.id,
					qty,
					sum: false,
					showCart: false,
					showUpsells: false,
					loading: (v) => (this.loading = v),
				})
				return
			}

			if (item) {
				if (item.qty == qty) return
				if (item.qty > 0 && qty == 0) {
					this.$shop.deleteCartItem({
						cartItemId: item.cartItemId,
						loading: (v) => (this.loading = v),
					})
				} else {
					this.$shop.updateCartItem({
						cartItemId: item.cartItemId,
						qty,
						loading: (v) => (this.loading = v),
					})
				}
			}
		},
		setQtyMap() {
			this.qtyMap = {}
			this.$nextTick(() => {
				let qtyMap = (this.qtyMap =
					this.order?.items.reduce((acc, item) => {
						acc[item.cartData.variantId] = item.qty
						return acc
					}, {}) || {})
				this.$assign(this.qtyMap, qtyMap)
			})
		},
		toScroll() {
			if (this.$b.mt) return
			window.scrollTo({ top: 112, behavior: 'smooth' })
		},
	},
	async mounted() {
		await this.$shopApi.get({
			url: '/bulkshop',
			loading: (v) => (this.fetching = v),
			onSuccess: ({ data }) => {
				let categoryOptions = []
				this.products = data.products.flatMap((product) => {
					if (!categoryOptions.includes(product.categories)) categoryOptions.push(product.categories)
					return product.variants.map((variant) => {
						let name = product.name
						if (variant.attrs.length) {
							name += ' - ' + variant.attrs.map((attr) => attr.v).join(' | ')
						}
						return {
							name,
							productId: variant.productId,
							tag: product.tag,
							categories: product.categories,
							urlName: product.urlName,
							stock: product.stock,
							image: product.images[0],
							id: variant.id,
							sku: variant.sku,
							pvPrice: variant.pvPrice,
							searchString: String(`${name} ${variant.sku}`)
								.toLowerCase()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, ''),
						}
					})
				})
				this.categoryOptions = categoryOptions
				this.setQtyMap()
			},
		})
	},
}
</script>

<template>
	<Container>
		<div class="px-2 py-4">
			<v-row>
				<v-col cols="12" md="6">
					<TextField
						v-model="search"
						:disabled="fetching"
						outlined
						label="Buscar por SKU o nombre..."
						dense
						clearable
						prepend-icon="mdi-magnify"
					/>
				</v-col>
				<v-col cols="12" md="6">
					<Autocomplete
						:items="categoryOptions"
						v-model="selectedCategory"
						dense
						outlined
						label="Filtrar por categoría..."
						:disable="fetching"
						clearable
						prepend-icon="mdi-filter"
					/>
				</v-col>
			</v-row>
		</div>
		<div class="overflow-auto" @mouseenter="toScroll()">
			<div class="inline-block">
				<div class="table-container">
					<div class="bulkshop-table" :class="loading && 'loading'">
						<div class="__header">
							<div class="text-center">Código</div>
							<div class="text-center">Tipo</div>
							<div class="__name">Producto</div>
							<div class="text-center">PVP</div>
							<div class="text-center">Precio</div>
							<div class="">Cantidad</div>
							<div class="text-center">Subtotal</div>
						</div>
						<v-virtual-scroll
							:items="filteredProducts"
							height="calc(100vh - 248px)"
							item-height="60"
							width="100%"
							min-width="100%"
							class="__body"
						>
							<template #default="{ item: variant }">
								<div v-if="variant.isCategoryLabel" class="__category-name pl-3">
									{{ variant.categories }}
								</div>
								<div v-else class="__row">
									<div class="__cell">{{ variant.sku }}</div>
									<div class="__cell">{{ variant.tag }}</div>
									<div class="__cell __name">
										<Media class="flex-shrink-0" :src="variant.image.thumb" width="45px" />
										<SafeLink
											:to="{
												name: 'product',
												params: { urlName: variant.urlName, id: variant.productId },
												query: { variant: variant.id },
											}"
											tabindex="-1"
										>
											{{ variant.name }}
										</SafeLink>
									</div>
									<div class="__cell __price">
										<PriceText
											v-if="variant.pvPrice.prevPrice"
											price-class="grey--text"
											:amount="variant.pvPrice.prevPrice"
											:currency="variant.pvPrice.priceConfig.currency.sign"
										/>
										<div v-else class="grey--text">-</div>
									</div>
									<div class="__cell __price">
										<PriceText
											:amount="variant.pvPrice.price"
											:currency="variant.pvPrice.priceConfig.currency.sign"
										/>
									</div>
									<div class="__cell __input">
										<Bulkshop-Input :value="qtyMap[variant.id]" @change="onBlur(variant, $event)" />
									</div>
									<div class="__cell __price">
										<PriceText
											class=""
											:amount="variant.pvPrice.price * qtyMap[variant.id] || 0"
											:currency="variant.pvPrice.priceConfig.currency.sign"
										/>
									</div>
								</div>
							</template>
						</v-virtual-scroll>
					</div>
				</div>
			</div>
		</div>
	</Container>
</template>

<style scoped lang="scss">
@mixin scrollbar {
	scrollbar-width: thin;
	scrollbar-color: var(--primary) var(--secondary);
}
.overflow-auto {
	height: 100%;
	@include scrollbar;
}
.inline-block {
	min-width: 100%;
	display: inline-block;
}
.table-container {
	position: relative;
	width: 1176px;
}
.bulkshop-table {
	background-color: white;
	border-radius: 8px;

	.__header {
		padding: 12px 22px 12px 12px;
		display: flex;
		border-bottom: 1px solid #777;
		gap: 10px;
		div {
			text-align: left;
			flex-shrink: 0;
			width: 120px;

			&.__name {
				width: 100%;
				flex-shrink: 1;
			}
		}
	}
	.__body {
		overflow-x: hidden;

		.__category-name {
			display: flex;
			align-items: center;
			height: 60px;
			background: #666;
			color: white;
			border-bottom: 1px solid #fff;
			font-size: 18px;
			font-weight: 500;
			padding-left: 24px;
		}
		.__row {
			padding-right: 12px;
			padding-left: 12px;
			display: flex;
			border-bottom: 1px solid #777;
			gap: 10px;

			.__cell {
				height: 60px;
				flex-shrink: 0;
				width: 120px;
				display: flex;
				align-items: center;
				justify-content: center;
				&.__input {
				}
				&.__price {
				}
				&.__name {
					line-height: 1.2;
					flex-shrink: 1;
					width: 100%;
					justify-content: flex-start;
					gap: 12px;
				}
			}
		}

		@include scrollbar;
	}
}
.loading {
	pointer-events: none;
	opacity: 0.5;
}
</style>

