<script>
export default {
	props: {
		value: Number,
	},
	data() {
		return {
			hasFocus: false,
		}
	},
}
</script>

<template>
	<div class="d-flex align-center" style="gap: 6px">
		<TextField
			placeholder="0"
			style="max-width: 95px"
			outlined
			dense
			@change="$emit('change', $event)"
			@blur="hasFocus = false"
			@focus="hasFocus = true"
			type="number"
			:value="value"
			min="0"
			v-bind="$attrs"
		/>
		<Button icon color="success" :class="!hasFocus && 'hide'">
			<v-icon>mdi-check</v-icon>
		</Button>
	</div>
</template>

<style scoped>
.hide {
	opacity: 0;
}
</style>

