var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UserpanelLayout',[(_vm.empty)?_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"pa-4 white"},[_vm._v("No hay movimientos de cuenta corriente")])]):_c('div',[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('CheckingAccount-Detail',{attrs:{"loading":_vm.loading && _vm.saldoImpagas === null},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Saldo Impagas")]},proxy:true},{key:"detail",fn:function(){return [_c('PriceText',{class:_vm.$b.m ? 'font-2' : 'font-4',attrs:{"sup-decimals":false,"amount":_vm.saldoImpagas}})]},proxy:true}])}),_c('CheckingAccount-Detail',{attrs:{"loading":_vm.loading && _vm.saldoVencidas === null},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Saldo Vencidas")]},proxy:true},{key:"detail",fn:function(){return [_c('PriceText',{class:_vm.$b.m ? 'font-2' : 'font-4',attrs:{"sup-decimals":false,"amount":_vm.saldoVencidas}})]},proxy:true}])}),_c('CheckingAccount-Detail',{attrs:{"loading":_vm.loading && _vm.saldoMaximo === null},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Saldo Máximo")]},proxy:true},{key:"detail",fn:function(){return [_c('PriceText',{style:(("color: " + _vm.colorSaldoMaximo + "!important")),attrs:{"sup-decimals":false,"amount":_vm.saldoMaximo}})]},proxy:true}])}),_c('CheckingAccount-Detail',{attrs:{"loading":_vm.loading && _vm.diasVencidos === null},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Dias Vencidos")]},proxy:true},{key:"detail",fn:function(){return [_c('div',{style:(("color: " + _vm.colorDiasVencidos))},[_vm._v(_vm._s(_vm.diasVencidos))])]},proxy:true}])})],1),_c('div',{staticClass:"pa-4 mt-4 white"},[_vm._v("Movimientos de cuenta corriente")]),_c('v-data-table',{class:_vm.loading && 'loading',attrs:{"headers":[
				{ text: 'Fecha', value: 'fecha' },
				{ text: 'Fecha Venc', value: 'fechaVenc' },
				{ text: 'Descripción', value: 'descripcion' },
				{ text: 'Debe', value: 'debe', align: 'end' },
				{ text: 'Haber', value: 'haber', align: 'end' },
				{ text: 'Archivo', value: 'urlImpresion', align: 'end' } ],"items":_vm.items,"loading":_vm.loading,"disable-sort":"","mobile-breakpoint":null,"loading-text":"Cargando datos...","server-items-length":_vm.serverItemsLength,"options":_vm.tableOptions,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
			var pagination = ref.pagination;
return [_c('v-data-footer',_vm._b({attrs:{"pagination":pagination,"options":_vm.tableOptions},on:{"update:options":function($event){_vm.tableOptions=$event}}},'v-data-footer',_vm.footerProps,false))]}},{key:"item",fn:function(ref){
			var item = ref.item;
			var index = ref.index;
return [_c('tr',{style:(index % 2 ? 'background-color: #f5f5f5' : '')},[_c('td',[_vm._v(_vm._s(_vm._f("date")(item.fecha)))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.fechaVenc)))]),_c('td',[_vm._v(_vm._s(item.descripcion))]),_c('td',[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('PriceText',{attrs:{"sup-decimals":false,"amount":item.debe}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('PriceText',{attrs:{"sup-decimals":false,"amount":item.haber}})],1)]),_c('td',[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('SafeLink',{attrs:{"to":_vm.baseUrl + item.urlImpresion}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }