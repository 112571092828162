<script>
export default {
	inject: ['manager'],
}
</script>

<template>
	<div class="product-card__financing-wrapper">
		<div
			v-for="(html, n) in manager.financingTagHtml"
			:key="n"
			:class="[
				'product-card__financing font-0 line-clamp-1 px-1 primary rounded',
				{
					'mb-1': !manager.hasTextTag && manager.discountPct,
					'alignment-center': manager.alignment == 'center',
					'alignment-right': manager.alignment == 'right',
				},
			]"
			v-html="html"
		></div>
	</div>
</template>

