<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],

	data() {
		return {
			cardType: this.$srv('productCardType.name', 'Design6'),
		}
	},

	computed: {
		elementOrder() {
			let cards = ['Design2', 'Design3', 'Design5', 'Design6']
			if (cards.some((i) => i == this.cardType)) return { name: 1, brand: 2 }
			else return { brand: 1, name: 2 }
		},
		nameClass() {
			if (this.elementOrder.name == 1) return ['mt-3', { 'mb-2': !this.brand }]
			else return ['mb-2', { 'mt-3': !this.brand }]
		},
		brandClass() {
			if (this.elementOrder.brand == 1) return 'mt-3'
			else return 'mb-2'
		},
		showWishlist() {
			let cards = ['Design1', 'Design5', 'Design8']
			return cards.some((i) => i == this.cardType)
		},
		eventIconTop() {
			return this.cardType == 'Design3' || this.cardType == 'Design7'
		},
		eventIconRight() {
			return this.cardType == 'Design7'
		},
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<div
		:class="[
			'h100 py-2 px-1',
			{
				'product-card-small-carousel-wrapper-mobile': $b.mt,
				'product-card-small-carousel-wrapper': $b.d,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
	>
		<v-card
			:hover="!outOfStock ? true : false"
			@click="cardClick()"
			:ripple="false"
			:class="[$bem('product-card-small-carousel'), { 'card-border': cardBorder }]"
			class="h100 d-flex flex-column base rounded-md overflow-hidden justify-space-between product-card"
		>
			<ProductCard-AttrsTags :vertical-card="false" class="pt-2 pl-2" />
			<v-hover v-model="hover">
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image-small-carousel">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{
								'product-card-small-carousel__image--smallWidth': $b.d,
								'product-card-small-carousel__image--smallWidth-mobile': $b.mt,
							}"
						/>
						<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="{
									'product-card-small-carousel__image--smallWidth': $b.d,
									'product-card-small-carousel__image--smallWidth-mobile': $b.mt,
								}"
							/>
						</div>
					</hook>

					<hook zone="event-icon-small-carousel">
						<CollectionEventIcon
							:product="product"
							:class="[
								$bem('__event-icon'),
								{
									'product-card-small-carousel__event-icon--top': eventIconTop,
									'product-card-small-carousel__event-icon--right': eventIconRight,
								},
							]"
							imgWidth="25px"
						/>
					</hook>
				</div>
			</v-hover>

			<div :class="[$bem('__data-cont'), { 'no-stock': outOfStock }, 'd-flex flex-column px-3 h100']">
				<hook zone="name-small-carousel" :style="{ order: elementOrder.name }">
					<ProductCardText
						:textClass="[
							$bem('__name', 'line-clamp-2 font-1 header'),
							nameClass,
							alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
						]"
						:text="product.name"
					/>
				</hook>

				<hook zone="brand-small-carousel" v-if="brand" :style="{ order: elementOrder.brand }">
					<ProductCardText
						:textClass="[
							$bem('__brand', 'font-0 line-clamp-1'),
							brandClass,
							alignment == 'left' ? 'text-start' : alignment == 'center' ? 'text-center' : 'text-end',
						]"
						:text="brand"
					/>
				</hook>
			</div>

			<ProductCard-Tags class="font-0 px-3" />

			<hook zone="wishlist-toggler-small-carousel" :class="{ 'no-stock': outOfStock }" v-if="showWishlist">
				<ProductWishlistToggler
					:productId="product.id"
					:class="$bem('__wishlist-toggler')"
					style="z-index: 1"
				/>
			</hook>
		</v-card>
	</div>
</template>

<style lang="scss" scoped>
.product-card-small-carousel-wrapper {
	min-height: 270px;
	width: 180px;
	min-width: 100px;
}

.product-card-small-carousel-wrapper-mobile {
	width: 170px;
}

.product-card-small-carousel {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__data-cont:nth-child(1) {
		order: var(--elementOrder-1, 1);
	}

	&__data-cont:nth-child(2) {
		order: var(--elementOrder-2, 2);
	}

	&__name {
		line-height: 1.3rem !important;
		display: -webkit-box;
	}

	&__brand {
		line-height: 1rem;
		display: -webkit-box;
	}

	&__event-icon {
		position: absolute;
		bottom: -2px;
		left: 8px;
		z-index: 1;
		width: 25px;

		&--top {
			top: 6px;
			bottom: unset;
		}

		&--right {
			right: 8px;
			left: unset;
		}
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
		width: 30px !important;
		height: 30px !important;
	}

	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}
}

.card-border {
	border: 1px solid #b2b2b2;
}

::v-deep .product-card-small-carousel__image--smallWidth {
	max-width: 180px;
	max-height: 180px;
}

::v-deep .product-card-small-carousel__image--smallWidth-mobile {
	max-width: 170px;
	max-height: 170px;
}

.no-stock {
	opacity: 0.4;
}
</style>

