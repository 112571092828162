import { views } from '@/v-shop/autoloader'
import { makeRoutesAccountGuard } from '@/v-shop/routes/routes-account-guard'

export default (options) => {
	const beforeEnter = makeRoutesAccountGuard(options)
	return [
		{
			beforeEnter,
			path: '/user/profile',
			name: 'user.bio-profile',
			component: views.BioProfile,
		},
		{
			beforeEnter,
			path: '/user/balances',
			name: 'user.bio-balances',
			component: views.BioBalances,
		},
		{
			beforeEnter,
			path: '/user/cuenta-corriente',
			name: 'user.checking-account',
			component: views.CheckingAccount,
		},
	]
}

