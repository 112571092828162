import { render, staticRenderFns } from "./view_Bulkshop.vue?vue&type=template&id=794f87fe&scoped=true"
import script from "./view_Bulkshop.vue?vue&type=script&lang=js"
export * from "./view_Bulkshop.vue?vue&type=script&lang=js"
import style0 from "./view_Bulkshop.vue?vue&type=style&index=0&id=794f87fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794f87fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCol,VRow,VVirtualScroll})
