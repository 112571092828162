import { views } from '@/v-shop/autoloader'

export default (options) => {
	return [
		{
			path: '/bulkshop',
			name: 'bulkshop',
			component: views.Bulkshop,
		},
	]
}

