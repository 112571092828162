<script>
export default {
	inject: ['hookContainer'],
	computed: {
		ProductPage() {
			return this.hookContainer()
		},
	},
}
</script>

<template>
	<div>
		<div class="d-flex align-center" style="gap: 10px">
			<div>
				<SafeLink
					v-if="ProductPage.product.brand && !$srv('ShopProductOptions.hideBrand')"
					:to="$shop.getShopRoute({ brand: ProductPage.product.brand.urlName })"
				>
					Marca:
					<span class="font-weight-bold nowrap">{{ ProductPage.product.brand.name }}</span>
				</SafeLink>
			</div>
			<div v-if="!$srv('ShopProductOptions.hideSku')">
				Sku: <span class="font-weight-bold">{{ ProductPage.selectedVariant.sku }}</span>
			</div>
			<div v-if="!$srv('ShopProductOptions.hideEan') && ProductPage.selectedVariant.ean">
				Ean: <span class="font-weight-bold">{{ ProductPage.selectedVariant.ean }}</span>
			</div>
		</div>
		<div
			class="my-2"
			v-if="
				ProductPage.marketplaceMode && ProductPage.showSeller && !$srv('ShopProductOptions.hideSeller')
			"
		>
			<div v-if="ProductPage.product.seller.logo" style="height: 20px" class="mt-2 mb-1">
				<Media :src="ProductPage.product.seller.logo" height="20px" max-width="130px" position="left" />
			</div>
			<div>
				Producto vendido por
				<span class="font-weight-bold">{{ ProductPage.product.seller.name.toUpperCase() }}</span>
			</div>
		</div>
	</div>
</template>

