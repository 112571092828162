<script>
import { get } from 'vuex-pathify'

export default {
	inject: ['manager'],
	data() {
		return {
			loading: false,
			updating: false,
		}
	},
	methods: {
		async addToCart(qty) {
			if (!this.isSelectable) return

			await this.$shop
				.addToCart({
					qty,
					productId: this.manager.product.id,
					variantId: this.manager.selectedVariant.id,
					sum: true,
					customization: this.manager.customization,
					showCart: false,
					loading: (v) => (this.manager.btnIsLoading = v),
					onValidation: ({ validation }) => this.$emit('validation', validation),
				})
				.then(() => null)
		},
		async removeFromCart() {
			if (!this.isSelectable) return

			if (!this.orderItem?.cartItemId) return
			const { cartItemId } = this.orderItem
			await this.$shop
				.deleteCartItem({ cartItemId, loading: (v) => (this.manager.btnIsLoading = v) })
				.then(() => null)
		},
		async updateCart(qty) {
			if (!this.isSelectable) return

			if (!this.orderItem?.cartItemId) return
			const { cartItemId } = this.orderItem
			await this.$shop
				.updateCartItem({
					cartItemId,
					qty,
					loading: (v) => (this.manager.btnIsLoading = v),
				})
				.then(() => null)
		},
		onClick() {
			if (!this.isSelectable) {
				this.$router.replace(this.manager.productRoute)
			}
		},
	},
	computed: {
		isSelectable() {
			return this.manager.product.hasUniqueVariant && !this.manager.product.info.customizationKey
		},
		minQty() {
			return this.orderItem?.cartData?.minQty
		},
		cQty: {
			get() {
				return this.orderItem?.qty || 0
			},
			async set(val) {
				if (this.updating || !this.isSelectable) return
				this.updating = true

				if (val && !this.orderItem?.qty) await this.addToCart(val)
				else if (val == 0 || val < this.minQty) await this.removeFromCart()
				else await this.updateCart(val)
				this.updating = false
			},
		},
		order: get('cart/order'),
		orderItem() {
			if (this.manager.product.hasUniqueVariant)
				return this.order?.items.find((item) => item.refId == this.manager.selectedVariant.id)

			const qty = this.manager.product.variants.reduce((acc, variant) => {
				const item = this.order?.items.find((item) => item.refId == variant.id)
				if (item) acc += item.qty
				return acc
			}, 0)

			return { qty }
		},
		hasUniqueVariant() {
			return this.manager.product.hasUniqueVariant
		},
		variant() {
			return this.manager.selectedVariant
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.variant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
	},
}
</script>

<template>
	<div
		v-if="
			!manager.spoHideQtyInput &&
			(manager.btnType == 'qty' || manager.btnType == 'qtyHover') &&
			manager.hasBtn
		"
		:class="['product-card__qty-input', { '--mobile': $b.m, '--loading': manager.btnIsLoading }]"
		@click="onClick"
	>
		<QtyInput
			v-if="!manager.outOfStock && manager.product.buyable"
			v-model="cQty"
			:disabled="!isSelectable"
			:max-qty="maxStockQty"
			:min-qty="minQty"
			allow-zero
			:unit-metric="manager.product.unitMetric"
			:pack-metric="manager.product.packMetric"
			:step="variant.metricFactor"
			:loading="manager.btnIsLoading"
		/>
	</div>
</template>

<style lang="scss" scoped>
.product-card__qty-input {
	&.--loading {
		opacity: 0.5;
		pointer-events: none;
	}

	::v-deep .qty-btn {
		&__wrapper {
			width: 100%;
		}
		&__container {
			justify-content: space-around;
			width: 100%;
		}
		&__tf {
			width: unset !important;
			max-width: unset !important;
		}
	}

	&.--mobile ::v-deep {
		.qty-btn {
			&__plus,
			&__minus {
				width: 25px;
			}
			&__tf {
				width: unset !important;
				& .v-input__slot {
					padding: 0;
					& input {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}
</style>

