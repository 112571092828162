<script>
import { get } from 'vuex-pathify'

export default {
	data() {
		return {
			loading: true,
			tableOptions: {
				page: 1,
				itemsPerPage: 25,
			},
			serverItemsLength: 0,
			baseUrl: '',

			saldoImpagas: null,
			saldoVencidas: null,
			saldoMaximo: null,
			diasVencidos: null,

			colorSaldoMaximo: '',
			colorDiasVencidos: '',

			items: [],
			empty: false,
		}
	},
	computed: {
		footerProps() {
			return {
				itemsPerPageOptions: [10, 25, 50, 100],
				itemsPerPageText: 'Items por página',
				showFirstLastPage: true,
			}
		},
		user: get('shop/user'),
		bioData() {
			return this.user.bioData
		},
	},
	methods: {
		loadBalances() {
			this.$shopApi.get({
				url: '/users-checking-account',
				query: { pageNumber: this.tableOptions.page, pageSize: this.tableOptions.itemsPerPage },
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					if (data.empty) {
						this.empty = true
						return
					}
					this.saldoImpagas = data.saldoImpagas
					this.items = data.movimientos?.items
					this.saldoVencidas = data.saldoVencidas
					this.saldoMaximo = data.saldoMaximo
					this.diasVencidos = data.diasVencidos
					this.colorSaldoMaximo = data.colorSaldoMaximo
					this.colorDiasVencidos = data.colorDiasVencidos
					this.tableOptions.pageStop = data.movimientos?.metadata.totalPages
					this.tableOptions.pageCount = data.movimientos?.metadata.totalPages
					this.serverItemsLength = data.movimientos.metadata.totalRecords
					this.baseUrl = data.baseUrl
					window.scrollTo({ top: 0 })
				},
			})
		},
	},
	watch: {
		async tableOptions(oldVal, newVal) {
			if (oldVal.itemsPerPage == newVal.itemsPerPage && oldVal.page == newVal.page) return
			this.loadBalances()
		},
	},
	mounted() {
		if (!this.bioData) {
			window.location.href = this.$router.resolve({ name: 'user.account' }).href
			return
		}

		this.loadBalances()
	},
}
</script>

<template>
	<UserpanelLayout>
		<div class="py-4" v-if="empty">
			<div class="pa-4 white">No hay movimientos de cuenta corriente</div>
		</div>
		<div v-else>
			<div class="d-flex flex-wrap py-2">
				<CheckingAccount-Detail :loading="loading && saldoImpagas === null">
					<template #title>Saldo Impagas</template>
					<template #detail>
						<PriceText
							:sup-decimals="false"
							:amount="saldoImpagas"
							:class="$b.m ? 'font-2' : 'font-4'"
						/>
					</template>
				</CheckingAccount-Detail>
				<CheckingAccount-Detail :loading="loading && saldoVencidas === null">
					<template #title>Saldo Vencidas</template>
					<template #detail>
						<PriceText
							:sup-decimals="false"
							:amount="saldoVencidas"
							:class="$b.m ? 'font-2' : 'font-4'"
						/>
					</template>
				</CheckingAccount-Detail>
				<CheckingAccount-Detail :loading="loading && saldoMaximo === null">
					<template #title>Saldo Máximo</template>
					<template #detail>
						<PriceText
							:sup-decimals="false"
							:amount="saldoMaximo"
							:style="`color: ${colorSaldoMaximo}!important`"
						/>
					</template>
				</CheckingAccount-Detail>
				<CheckingAccount-Detail :loading="loading && diasVencidos === null">
					<template #title>Dias Vencidos</template>
					<template #detail>
						<div :style="`color: ${colorDiasVencidos}`">{{ diasVencidos }}</div>
					</template>
				</CheckingAccount-Detail>
			</div>
			<div class="pa-4 mt-4 white">Movimientos de cuenta corriente</div>
			<v-data-table
				class=""
				:class="loading && 'loading'"
				:headers="[
					{ text: 'Fecha', value: 'fecha' },
					{ text: 'Fecha Venc', value: 'fechaVenc' },
					{ text: 'Descripción', value: 'descripcion' },
					{ text: 'Debe', value: 'debe', align: 'end' },
					{ text: 'Haber', value: 'haber', align: 'end' },
					{ text: 'Archivo', value: 'urlImpresion', align: 'end' },
				]"
				:items="items"
				:loading="loading"
				disable-sort
				:mobile-breakpoint="null"
				loading-text="Cargando datos..."
				:server-items-length="serverItemsLength"
				:options.sync="tableOptions"
				:footer-props="footerProps"
			>
				<template #top="{ pagination }">
					<v-data-footer :pagination="pagination" :options.sync="tableOptions" v-bind="footerProps">
					</v-data-footer>
				</template>
				<template #item="{ item, index }">
					<tr :style="index % 2 ? 'background-color: #f5f5f5' : ''">
						<td>{{ item.fecha | date }}</td>
						<td>{{ item.fechaVenc | date }}</td>
						<td>{{ item.descripcion }}</td>
						<td>
							<div class="d-flex justify-end align-center">
								<PriceText :sup-decimals="false" :amount="item.debe" />
							</div>
						</td>
						<td>
							<div class="d-flex justify-end align-center">
								<PriceText :sup-decimals="false" :amount="item.haber" />
							</div>
						</td>
						<td>
							<div class="d-flex justify-end align-center">
								<SafeLink :to="baseUrl + item.urlImpresion"
									><v-icon>mdi-open-in-new</v-icon></SafeLink
								>
							</div>
						</td>
					</tr>
				</template>
			</v-data-table>
		</div>
	</UserpanelLayout>
</template>

<style scoped>
.loading {
	pointer-events: none;
	opacity: 0.5;
}

::v-deep thead {
	background-color: #f5f5f5;
}
</style>

