import productMixin from './product-mixin'
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'

import { sync } from 'vuex-pathify'

export default {
	mixins: [productMixin('card'), shopProductOptions()],
	props: {
		type: String,
		product: Object,
		listId: String,
		listIndex: Number,
		timerProduct: { type: Boolean, default: false },
		verticalCard: { type: Boolean, default: true },
		featured: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		mobileBtn: { type: Boolean, default: false },
		forMobileShop: { type: Boolean, default: false },
		pbForBtnHover: { type: String, default: '60px' },
		upsellCard: { type: Boolean, default: false },
	},
	data() {
		return {
			firstImageHover: false,
			configData: this.$srv('productCardType', {}),
			showBtnHoverMobile: false,
			btnIsLoading: false,
			hover: false,
			qty: 1,
		}
	},
	computed: {
		// btnIsLoading: sync('shop/ctaIsLoading'),
		mainImage() {
			let img = this.product.images[0].square
			img.alt = this.product.name
			return img
		},
		secondImage() {
			let img = this.product.images[1]?.square
			if (img) img.alt = this.product.name
			return img
		},
		textTag() {
			if (this.textTags.length) return this.textTags[0].text
			else return null
		},
		alignment() {
			return this.configData.alignment || 'left'
		},
		hasBtn() {
			if (this.upsellCard || this.spoHideBuyBtn) return false
			else {
				if (this.$b.d) return this.configData?.btnDesktop != 'noBtn'
				return this.configData?.btnMobile != 'noBtn'
			}
		},
		btnHover() {
			if (this.$b.d && !this.spoHideBuyBtn)
				return this.configData?.btnDesktop == 'btnHover' || this.configData?.btnDesktop == 'qtyHover'
			return this.configData?.btnMobile == 'btnHover' || this.configData?.btnMobile == 'qtyHover'
		},
		btnType() {
			if (this.$b.d) return this.configData?.btnDesktop
			return this.configData?.btnMobile
		},
		btn() {
			if (this.$b.d) return this.configData?.btnDesktop == 'btn' || this.configData?.btnDesktop == 'qty'
			return this.configData?.btnMobile == 'btn' || this.configData?.btnMobile == 'qty'
		},
		cardBorder() {
			return this.configData.cardBorder || false
		},
		imgHover() {
			return this.$b.d
		},

		hoverForBtn() {
			return 'calc(100% + ' + this.pbForBtnHover + ')'
		},
		smallWrapperClass() {
			const design = this.type?.toLowerCase() || this.configData.name.toLowerCase() || 'design1'

			if (this.$b.m) {
				if (!this.forMobileShop) {
					if (this.btnHover) return `product-card-${design}-small-wrapper-mobile-forBtn`
					else return `product-card-${design}-small-wrapper-mobile`
				} else {
					if (this.btnHover) return `product-card-${design}-small-wrapper-forBtn`
					else return `product-card-${design}-small-wrapper`
				}
			}

			if (this.$b.t) {
				if (this.btnHover) return `product-card-${design}-small-wrapper-forBtn`
				else return `product-card-${design}-small-wrapper-mobile`
			}

			if (this.btnHover) return `product-card-${design}-small-wrapper-forBtn`
			else return `product-card-${design}-small-wrapper`
		},
		verticalWrapperClasses() {
			const design = this.type?.toLowerCase() || this.configData.name.toLowerCase() || 'design1'
			const cardClass = `product-card-${design}-vertical-wrapper`
			const hasAttrsTagsConfig = this.$srv('productCardTags', false)

			return [
				`h100 ${cardClass}`,
				{
					[`${cardClass}-forBtn`]: this.btnHover,
					[`${cardClass}--for-btn-with-tags`]: this.btnHover && hasAttrsTagsConfig,
				},
			]
		},

		verticalCardClasses() {
			return 'd-flex flex-column base rounded-md overflow-hidden product-card pb-2'
		},

		horizontalCardClasses() {
			return 'd-flex overflow-hidden base product-card py-2'
		},
	},
	methods: {
		async cardClick() {
			this.$eventer().trigger('product:click', {
				product: this.product,
				variant: this.selectedVariant,
				listId: this.listId,
				index: this.listIndex,
			})
			this.$emit('card:click', this.product)
		},

		async btnClicked() {
			if (this.product.hasUniqueVariant || !this.product.info.customizationKey) {
				return this.$shop.addToCart({
					qty: this.qty,
					productId: this.product.id,
					variantId: this.selectedVariant.id,
					sum: true,
					customization: this.customization,
					loading: (v) => (this.btnIsLoading = v),
					onValidation: ({ validation }) => this.$emit('validation', validation),
				})
			}
		},

		onLinkClickMobile(navigate, $event) {
			if (this.btnHover) {
				if (this.showBtnHoverMobile) navigate($event)
				else {
					this.showBtnHoverMobile = true
					$event.preventDefault()
				}
			} else navigate($event)
		},
		onLinkBlurMobile() {
			if (this.btnHover) this.showBtnHoverMobile = false
			else null
		},
	},
}

