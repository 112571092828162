var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.slides.length)?_c('div',[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({attrs:{"arrowsBgHalfRounded":"","loop":"","autoplay-delay":_vm.data.autoplayDelay},on:{"slideIndex":function($event){return _vm.getIndex($event)}}},'Carousel',_vm.carouselProps,false),_vm._l((_vm.data.slides),function(i,index){return _c('div',{key:index,staticClass:"overflow-hidden w100"},[_c('SafeLink',{directives:[{name:"evt-click",rawName:"v-evt-click"}],attrs:{"to":i.link,"id":i.labelId}},[_c('Media',{attrs:{"src":_vm.$b({ mt: i.imgMobile, d: i.img }),"img-class":{ 'zoom-in': index == _vm.slideIndex && i.zoomEffect },"cover":"","width":"100%","aspectRatio":_vm.aspectRatio,"videoProps":{
							videoAttrs: {
								autoplay: 'autoplay',
								muted: 'muted',
								loop: 'loop',
								playsinline: 'playsinline',
							},
						},"youtube-video-props":{
							iframeAttrs: {
								allow: 'autoplay; encrypted-media',
								allowfullscreen: 'allowfullscreen',
								playsinline: 'playsinline',
							},
							urlOptions: {
								controls: 0,
								showinfo: 0,
								rel: 0,
								autoplay: 1,
								mute: 1,
								loop: 1,
								playlist: _vm.videoId(i),
							},
						},"vimeo-video-props":{
							iframeAttrs: {
								allow: 'autoplay; encrypted-media',
								allowfullscreen: 'allowfullscreen',
								playsinline: 'playsinline',
							},
							urlOptions: {
								background: 1,
								muted: 1,
								autoplay: 1,
							},
						}}},[_c('div',{staticClass:"h100 w100 d-flex justify-center flex-column align-center",class:{ 'background-overlay': _vm.isVideo(i) && i.darkenSlide }},[(i.title)?_c('h2',{staticClass:"font-4 font-lg-16 font-md-7 font-weight-bold primary pa-1 text-center",class:_vm.textClass(index == _vm.slideIndex, i.textEffect, 'title')},[_vm._v(" "+_vm._s(i.title)+" ")]):_vm._e(),(i.subtitle)?_c('h3',{staticClass:"font-1 font-lg-8 font-md-5 font-weight-medium secondary pa-1 text-center",class:[
									_vm.textClass(index == _vm.slideIndex, i.textEffect, 'subtitle'),
									{
										'mt-2': i.title && !_vm.$b.m,
										'mt-1': i.title && _vm.$b.m,
									} ]},[_vm._v(" "+_vm._s(i.subtitle)+" ")]):_vm._e(),(i.cta)?_c('v-btn',{staticClass:"cta font-weight-bold",class:[
									_vm.btnClass(index == _vm.slideIndex, i.subtitle, i.textEffect),
									{
										'mt-5': !_vm.$b.m,
										'mt-1': _vm.$b.m,
									} ],attrs:{"xSmall":_vm.$b.m}},[_vm._v(_vm._s(i.cta))]):_vm._e()],1)])],1)],1)}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }