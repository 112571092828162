<script>
export default {
	name: 'QtyInput',
	props: {
		qtyModel: { type: Number, default: 1 },
		disabled: Boolean,
		maxQty: Number,
		minQty: Number,
		loading: Boolean,
		unitMetric: String,
		packMetric: String,
		allowZero: Boolean,
		step: {
			type: Number,
			default: 1,
		},
		price: Number,
		currency: String,
		showCalculatedPrice: Boolean,
	},
	model: {
		prop: 'qtyModel',
		event: 'updateQtyModel',
	},
	data() {
		return {
			qty: null,
			qtyInput: null,
			qtyNumber: null,
			showInput: false,
		}
	},
	computed: {
		unitMetricWord() {
			if (!this.unitMetric) return ''
			return this.qty == 1 ? this.unitMetric.split('/')[0] : this.unitMetric.split('/').join('')
		},
		packMetricWord() {
			if (!this.packMetric) return ''
			return this.packQty == 1 ? this.packMetric.split('/')[0] : this.packMetric.split('/').join('')
		},
		cMaxQty() {
			return this.maxQty ? this.roundToStep(this.maxQty, false) : null
		},
		cMinQty() {
			return this.minQty ? this.roundToStep(this.minQty, true) : null
		},
		cStep() {
			return this.step || 1
		},
		packQty() {
			return Math.round(this.qty / this.cStep)
		},
		cQtyInput() {
			return `${this.qtyNumber} ${this.unitMetricWord}`
		},
	},
	watch: {
		qty(value) {
			this.$emit('updateQtyModel', value)
		},
		qtyModel(value) {
			this.setQty(value)
		},
	},
	methods: {
		selectInput() {
			this.$refs.hiddenInput.$el.querySelector('input').select()
		},
		roundToStep(n, up = false) {
			let div = Number((n / this.cStep).toFixed(2))
			let result = up ? Math.ceil(div) : Math.floor(div)
			return Number((this.cStep * result).toFixed(2))
		},
		addQty() {
			this.setQty(this.qty + this.cStep)
		},
		subtractQty() {
			if (!this.qty) return
			this.setQty(this.qty - this.cStep)
		},
		setQty(n) {
			if (isNaN(n)) {
				n = this.qty
			}

			if (this.allowZero) {
				if (n === 0 || (n < this.minQty && this.qty > n)) {
					this.qty = 0
					this.qtyNumber = 0
					return
				}
			}

			let newQty = this.limitQty(n)
			newQty = this.roundToStep(newQty, true)
			this.qty = newQty
			this.qtyNumber = newQty
		},
		limitQty(n) {
			if (this.cMaxQty) {
				n = Math.min(this.cMaxQty, n)
			}
			if (this.cMinQty) {
				n = Math.max(this.cMinQty, n)
			}
			return Math.max(this.cStep, n)
		},
		onFocus() {
			this.qtyInput = this.qty
		},
		onBlur() {
			this.setQty()
			this.showInput = false
		},
		doShowInput() {
			this.showInput = true
			this.$nextTick(() => {
				this.selectInput()
			})
		},
	},
	created() {
		this.setQty(this.qtyModel)
	},
	beforeUpdate() {
		this.setQty(this.qtyModel)
	},
}
</script>

<template>
	<div :class="!$b.m && 'd-flex'">
		<div class="base d-flex qty-btn qty-btn__wrapper">
			<div class="d-flex align-center qty-btn__container">
				<Button
					:color="$vars.basetext"
					@click="subtractQty"
					:disabled="loading || disabled"
					:ripple="false"
					class="d-inline rounded-0 qty-btn qty-btn__minus"
					text
					plain
					icon
					aria-label="menos"
				>
					<v-icon>mdi-minus</v-icon>
				</Button>
				<div
					class="qty-btn__tf-container"
					:class="{ 'qty-btn__tf-container--pack-metric': !!packMetric }"
				>
					<v-text-field
						v-if="showInput"
						ref="hiddenInput"
						@change="setQty"
						@blur="onBlur"
						@click="selectInput"
						@keydown.enter="onBlur"
						v-model="qtyNumber"
						:style="`color: ${$vars.basetext}`"
						class="qty-btn__tf rounded-0 font-weight-black"
						hide-details
						flat
						solo
						dense
						small
						:height="40"
						type="number"
					/>
					<v-text-field
						v-else
						@click.native="doShowInput"
						ref="displayInput"
						type="tel"
						:value="cQtyInput"
						:loading="loading"
						:disabled="true"
						:style="`color: ${$vars.basetext}`"
						class="qty-btn__tf rounded-0 font-weight-black"
						hide-details
						flat
						solo
						dense
						small
						:height="40"
					/>
					<span v-if="packMetric" class="qty-btn__tf-pack-metric-text"
						>{{ packQty }} {{ packMetricWord }}</span
					>
				</div>

				<Button
					:color="$vars.basetext"
					@click="addQty"
					:ripple="false"
					:disabled="loading || disabled"
					class="d-inline rounded-0 qty-btn__plus"
					text
					plain
					icon
					aria-label="mas"
				>
					<v-icon>mdi-plus</v-icon>
				</Button>
			</div>
		</div>
		<div :class="$b.m ? 'mt-2' : 'pl-3'" class="d-flex align-center" v-if="showCalculatedPrice">
			<div>
				<div class="font-0">Precio final:</div>
				<ProductPrice custom-class="font-4 price" :amount="price * qtyNumber" :currency="currency" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
::v-deep input[type='number'] {
	-moz-appearance: textfield;
}

.qty-btn {
	&__minus {
		border-right: 1px solid #eee;
		width: 45px;
	}
	&__plus {
		border-left: 1px solid #eee;
		width: 45px;
	}
	&__container {
		border: 1px solid #eee;
	}
	&__tf-container {
		position: relative;
		&--pack-metric ::v-deep .v-text-field__slot {
			position: relative;
			top: -5px;
		}
	}
	&__tf {
		max-width: 120px !important;
		width: 120px !important;
		border-left: none !important;
	}
	&__tf ::v-deep .v-input__slot {
		background-color: transparent !important;
		input {
			color: var(--basetext) !important;
			text-align: center;
			font-size: 1rem;
			text-overflow: ellipsis;
			pointer-events: none;
		}
	}
	&__tf-pack-metric-text {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0;
		font-size: 12px;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
	}
}
</style>

