var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.manager.hasTextTag)?_c('div',_vm._l((_vm.manager.textTags),function(ref,n){
var text = ref.text;
return _c('div',{key:n,class:[
			'product-card__text-tag font-0 line-clamp-1 px-1 error rounded',
			{
				'alignment-center': _vm.manager.alignment == 'center',
				'alignment-right mt-1': _vm.manager.alignment == 'right',
			} ]},[_vm._v(" "+_vm._s(text)+" ")])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }