<script>
import { get, sync } from 'vuex-pathify'
import whatsappConfigMixin from '@/v-shop/mixins/whatsapp-config-mixin.js'

export default {
	mixins: [whatsappConfigMixin],
	data() {
		return {
			mounted: false,
			config: this.$srv('ActionBarMobile'),
			showSubmenu: false,
			dynamicItems: {
				home: {
					type: 'products',
					icon: 'mdi-store',
					text: 'Productos',
					action: this.productAction,
				},
				shop: {
					type: 'filter',
					icon: 'mdi-filter-outline',
					text: 'Filtros',
					action: this.filterAction,
				},
				product: {
					type: 'buy',
					icon: 'mdi-shopping-outline',
					text: 'Comprar',
					action: this.buyAction,
				},
			},
		}
	},
	mounted() {
		this.mounted = true
	},
	created() {
		if (!this.config) return
		this.config.items.forEach((item) => {
			item.action = this[`${item.type}Action`]?.(item) || function () {}
		})
	},
	computed: {
		user: get('shop/user'),
		routeLoading: get('shop/routeLoading'),
		ctaIsLoading: get('shop/ctaIsLoading'),
		offersDrawer: sync('shop/offersDrawer'),
		iPhoneClass() {
			const m = this.mounted
			if (!this.$window) return
			const userAgent = this.$window.navigator.userAgent
			const isIphone = /iPhone/.test(userAgent) && !this.$window.MSStream
			const isSafari =
				userAgent.includes('Safari') && !userAgent.includes('Chrome') && !userAgent.includes('Edge')
			const useClass = isIphone
			return useClass ? 'pb-4' : ''
		},
		items() {
			return this.config.items.filter((item) => {
				if (item.type == 'whatsapp') return this.whatsAppConfigs.length
				if (item.type == 'offersBoard') return this.$srv('offerDrawer')?.products.length
				return true
			})
		},
		dynamicItem() {
			return this.dynamicItems[this.$route.name] || this.dynamicItems.home
		},
		visibleItems() {
			const res = [
				this.dynamicItem,
				{ icon: 'mdi-magnify', text: 'Buscar', action: () => this.searchAction() },
				...this.items.slice(0, 2),
			]

			if (this.items.length > 2) {
				res.push({
					icon: 'mdi-dots-horizontal',
					text: 'Más',
					action: this.doShowSubmenu,
				})
			}
			return res
		},
		additionalItems() {
			return this.items.slice(2)
		},
		cssVars() {
			return Object.assign(this.$vars, this.config.cssVars)
		},
		getDynamic() {
			return (name) => this?.[name]
		},
		buyLoading() {
			return this.$store.get('shop/ctaIsLoading')
		},
		columnStyle() {
			return {}
		},
		textStyle() {
			return { fontSize: '12px' }
		},
		iconSize() {
			return '26px'
		},
	},
	methods: {
		linkAction(item) {
			return () => {
				this.showSubmenu = false
				this.showWhatsappChat = false

				const absoluteUrlRegex = /^([a-z]+:\/\/|(mailto|tel):)/i

				const linkIsAbsolute = () => {
					return typeof item.link == 'string' && item.link.match(absoluteUrlRegex)
				}
				const linkIsExternal = () => {
					return linkIsAbsolute() && !item.link.startsWith(process.env.VUE_APP_BASE_URL)
				}

				if (linkIsExternal()) {
					window.open(item.link, '_blank')
				} else {
					this.$router.replace(item.link)
				}
			}
		},
		categoryAction(item) {
			return () => {
				const route = this.$shop.getShopRoute({ categories: item.category.familyUrlNames })
				this.$router.replace(route)
			}
		},
		loginRegisterAction() {
			return () => {
				new Promise((resolve, reject) => {
					if (this.user) {
						this.$router
							.push({ name: 'user.account' })
							.then(resolve)
							.catch((e) => reject(e))
					} else {
						this.$nextTick(() => {
							this.$store.set('shop/loginDrawer', true)
							resolve()
						})
					}
				})
					.catch()
					.finally(() => {
						this.showSubmenu = false
					})
			}
		},
		offersBoardAction() {
			return () => {
				this.offersDrawer = true
				this.showSubmenu = false
			}
		},
		productAction() {
			this.$shop.goShop()
		},
		filterAction() {
			this.$eventer().trigger('toggleFiltersDrawer')
		},
		buyAction() {
			this.$eventer().trigger('buyProduct')
		},
		searchAction() {
			this.$eventer().trigger('focusMobileSearch')
		},
		whatsappAction() {
			return () => {
				this.showSubmenu = false
				this.showWhatsappChat = true
			}
		},
		doShowSubmenu() {
			this.showSubmenu = true
			this.showWhatsappChat = false
		},
	},
	watch: {
		routeLoading() {
			this.showSubmenu = false
		},
	},
}
</script>

<template>
	<div v-if="config && !showWhatsappChat">
		<div class="pusher"></div>
		<css-vars :default-vars="cssVars" selector=".action-bar">
			<div :class="[$bem('action-bar__container'), iPhoneClass]" class="action-bar base">
				<div :class="$bem('__submenu')" v-if="showSubmenu">
					<div :class="$bem('__submenu__close')" class="px-2">
						<div class="font-weight-bold font-2 headerfont d-flex align-center">Más Opciones</div>
						<v-spacer></v-spacer>
						<Button text color="link" @click="showSubmenu = false">
							<v-icon>mdi-close</v-icon> Cerrar
						</Button>
					</div>
					<div class="px-2">
						<v-divider class="mb-4" />
					</div>

					<div
						:class="$bem('__submenu__item')"
						v-for="({ icon, text, action }, n) in additionalItems"
						:key="n"
						@click="action"
						v-ripple
					>
						<div>
							<v-icon class="mr-3" :color="cssVars.basetext || $vars.basetext">{{ icon }}</v-icon>
							<span>{{ text }}</span>
						</div>
					</div>
				</div>

				<div v-else>
					<v-row class="py-3">
						<v-col
							:cols="12 / visibleItems.length"
							v-for="(item, n) in visibleItems"
							:key="n"
							:class="$bem('__item')"
							v-ripple
							@click="item.action"
							:style="columnStyle"
						>
							<v-progress-circular v-if="getDynamic(`${item.type}Loading`)" indeterminate :size="26" />
							<v-badge
								:content="getDynamic(`${item.type}Badge`)"
								:value="!!getDynamic(`${item.type}Badge`)"
								overlap
								v-else
							>
								<v-icon
									:color="item.iconColor || getDynamic(`${item.type}Color`) || cssVars.basetext"
									:size="iconSize"
									>{{ item.icon }}</v-icon
								>
							</v-badge>
							<div :style="[getDynamic(`${item.type}Style`), textStyle, { color: item.textColor }]">
								{{ item.text }}
							</div>
						</v-col>
					</v-row>
				</div>
			</div>
		</css-vars>
	</div>
</template>

<style scoped lang="scss">
.pusher {
	height: 81px;
}

.action-bar {
	&__container {
		box-shadow: -2px 1px 2px 1px rgba(0, 0, 0, 0.2);
		position: fixed;
		bottom: -1px;
		z-index: 2;
		width: 100%;
		padding: 5px;

		&__item {
			text-align: center;
			&:hover {
				cursor: pointer;
			}
		}

		&__submenu {
			&__item {
				padding: 20px 10px 20px 20px;
				font-size: 20px;
				&:not(:last-child) {
					border-bottom: 1px solid #ccc;
				}
			}
			&__close {
				padding-top: 10px;
				display: flex;
				justify-content: center;
			}
		}
	}
}
</style>

