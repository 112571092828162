<script>
import modelBypassMixin from '@/utils/model-bypass-mixin'
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'

export default {
	mixins: [modelBypassMixin(String, null), checkoutMixin],
	props: {
		selector: Object,
	},
	computed: {
		allowPresupuesto() {
			return !!this.selector.checkoutData.allowPresupuesto
		},
	},
	mounted() {
		if (this.allowPresupuesto) {
			let payload = this.checkout.stepsData?.payment?.payload
			if (payload?.selection?.method.methodKey == 'CtaCte' && payload.input) {
				this.iValue = payload.input
			} else {
				this.iValue = 'f'
			}
		} else {
			this.iValue = null
		}
		this.$emit('canSubmit', true)
	},
}
</script>

<template>
	<div v-if="allowPresupuesto" class="py-4">
		<div class="pb-2"><b>Elige la forma de facturación para tu pago con Cuenta Corriente</b></div>
		<Select
			:items="[
				{ text: 'Factura', value: 'f' },
				{ text: 'Presupuesto', value: 'p' },
			]"
			v-model="iValue"
			label="Facturación"
		/>
	</div>
</template>

<style></style>

