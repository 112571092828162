<script>
export default {
	props: {
		loading: Boolean,
	},
}
</script>

<template>
	<v-col cols="6" md="3" class="d-flex justify-center align-center" style="border-right: 1px solid #ccc">
		<div class="px-2 px-sm-4">
			<div class="text-center">
				<slot name="title" />
			</div>
			<div class="d-flex justify-center align-center" :class="$b.m ? 'font-2' : 'font-4'">
				<slot v-if="!loading" name="detail" />
				<v-progress-circular v-else indeterminate></v-progress-circular>
			</div>
		</div>
	</v-col>
</template>

<style scoped lang="scss">
.vertical-line {
	position: absolute;
	height: 70px;
	border-right: 1px solid #ccc;
	top: 25px;
	right: 0;

	&.--mobile {
		top: 0;
		height: 85px;
	}
}
</style>

