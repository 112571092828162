<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'
import LazyHydrate from 'vue-lazy-hydration'

export default {
	component: {
		LazyHydrate,
	},
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			slideIndex: 0,
		}
	},
	computed: {
		sideItems() {
			return numberedPropsToArray(this.data, 2)
		},
	},
	methods: {
		getIndex(index) {
			this.slideIndex = index
		},
		textClass(transition, effect, textType) {
			if (transition) {
				switch (effect) {
					case 'up':
						return `${textType}-up`
					case 'down':
						return `${textType}-down`
					case 'zoom':
						return `${textType}-zoom`
					case 'fade':
						return `${textType}-fade`
					default:
						return null
				}
			} else return null
		},
		btnClass(transition, subtitle, effect) {
			if (transition && subtitle) {
				switch (effect) {
					case 'up':
						return 'btn-up'
					case 'down':
						return 'btn-down'
					case 'zoom':
						return 'btn-zoom'
					case 'fade':
						return 'btn-fade'
					default:
						return null
				}
			} else if (transition && !subtitle) {
				switch (effect) {
					case 'up':
						return 'subtitle-up'
					case 'down':
						return 'subtitle-down'
					case 'zoom':
						return 'subtitle-zoom'
					case 'fade':
						return 'subtitle-fade'
					default:
						return null
				}
			} else return null
		},
	},
}
</script>

<template>
	<Container
		v-if="data.sliderCards && data.sliderCards.length"
		class="no-mobile-pa"
		:class="{ 'my-n6 my-sm-0': index == 0 }"
	>
		<v-row>
			<v-col cols="12" sm="8">
				<LazyHydrate when-visible>
					<Carousel
						:dotContainerFullWidthMobile="data.sliderCards.length >= 15"
						loop
						:autoplay-delay="Number(data.autoplayDelay)"
						class="w100"
						center
						arrowsBgHalfRounded
						v-bind="
							$b.props({
								showArrows: true,
								showDots: true,
							})
						"
						@slideIndex="getIndex($event)"
					>
						<div
							v-for="(item, i) of data.sliderCards"
							:key="i"
							:class="$b({ td: 'rounded-lg' })"
							class="overflow-hidden"
						>
							<SafeLink
								:to="item.link"
								:class="$b({ td: 'rounded-lg' })"
								:id="item.labelId"
								v-evt-click
							>
								<Media
									width="100%"
									:src="$b({ mt: item.imgMobile, d: item.img })"
									:img-class="[
										$b({ td: 'rounded-lg' }),
										{ 'zoom-in': i == slideIndex && item.zoomEffect },
									]"
								>
									<div
										class="h100 w100 d-flex justify-center flex-column align-center"
										:class="{
											'background-overlay': item.darkenSlide,
											'rounded-lg': $b.td,
										}"
									>
										<h2
											class="font-4 font-lg-16 font-md-7 font-weight-bold primary pa-1 text-center"
											:class="textClass(i == slideIndex, item.textEffect, 'title')"
											v-if="item.title"
										>
											{{ item.title }}
										</h2>
										<h3
											class="font-1 font-lg-8 font-md-5 font-weight-medium secondary pa-1 text-center"
											v-if="item.subtitle"
											:class="[
												textClass(i == slideIndex, item.textEffect, 'subtitle'),
												{
													'mt-2': item.title && !$b.m,
													'mt-1': item.title && $b.m,
												},
											]"
										>
											{{ item.subtitle }}
										</h3>
										<v-btn
											class="cta font-weight-bold"
											:xSmall="$b.m"
											:class="[
												btnClass(i == slideIndex, item.subtitle, item.textEffect),
												{
													'mt-5': !$b.m,
													'mt-1': $b.m,
												},
											]"
											v-if="item.cta"
											>{{ item.cta }}</v-btn
										>
									</div>
								</Media>
							</SafeLink>
						</div>
					</Carousel>
				</LazyHydrate>
			</v-col>

			<v-col cols="12" sm="4" :class="{ 'mt-5': $b.d }">
				<v-row>
					<v-col cols="6" sm="12" v-for="(item, i) of sideItems" :key="i">
						<SafeLink :to="item.link" class="w100" :id="item.labelId" v-evt-click>
							<Media :src="item.img" img-class="rounded-lg" width="100%">
								<h1 class="seo-text">{{ item.textSEO }}</h1>
							</Media>
						</SafeLink>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped lang="scss">
.background-overlay {
	background-color: #424242aa;
}

@mixin textAnimation($name, $percValue, $initialTransformValue: null, $finalTransformValue: null) {
	@keyframes #{$name} {
		0% {
			transform: $initialTransformValue;
			opacity: 0;
		}
		#{$percValue} {
			transform: $initialTransformValue;
			opacity: 0;
		}
		100% {
			transform: $finalTransformValue;
			opacity: 1;
		}
	}
}

.title-up {
	@include textAnimation(titleUp, 20%, translateY(30px), translateY(0));
	animation: titleUp 2s ease forwards;
}

.subtitle-up {
	@include textAnimation(subtitleUp, 30%, translateY(30px), translateY(0));
	animation: subtitleUp 2s ease forwards;
}

.btn-up {
	@include textAnimation(btnUp, 40%, translateY(30px), translateY(0));
	animation: btnUp 2s ease forwards;
}

.title-down {
	@include textAnimation(titleDown, 20%, translateY(-30px), translateY(0));
	animation: titleDown 2s ease forwards;
}

.subtitle-down {
	@include textAnimation(subtitleDown, 30%, translateY(-30px), translateY(0));
	animation: subtitleDown 2s ease forwards;
}

.btn-down {
	@include textAnimation(btnDown, 40%, translateY(-30px), translateY(0));
	animation: btnDown 2s ease forwards;
}

.title-zoom {
	@include textAnimation(titleZoom, 20%, scale(2), scale(1));
	animation: titleZoom 2s ease forwards;
}

.subtitle-zoom {
	@include textAnimation(subtitleZoom, 30%, scale(2), scale(1));
	animation: subtitleZoom 2s ease forwards;
}

.btn-zoom {
	@include textAnimation(btnZoom, 40%, scale(2), scale(1));
	animation: btnZoom 2s ease forwards;
}

.title-fade {
	@include textAnimation(titleFade, 20%);
	animation: titleFade 2s ease forwards;
}

.subtitle-fade {
	@include textAnimation(subtitleFade, 30%);
	animation: subtitleFade 2s ease forwards;
}

.btn-fade {
	@include textAnimation(btnFade, 60%);
	animation: btnFade 2s ease forwards;
}

::v-deep .zoom-in {
	animation: zoomIn 12s ease forwards;
}

@keyframes zoomIn {
	0% {
		scale: 1;
	}

	100% {
		scale: 1.3;
	}
}
</style>

