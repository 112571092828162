<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="['h100 w100', smallWrapperClass]"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				elevation="2"
				@click="cardClick"
				:ripple="false"
				:class="[
					'd-flex flex-column rounded-md overflow-hidden base product-card',
					$bem('product-card-design2-small'),
					{ 'card-border': cardBorder },
				]"
				:style="{ height: forMobileShop && btnHover && hover ? 'calc(100% + 50px)' : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }, 'mx-2 mt-2']">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="
								$b.mt
									? 'product-card-design2-small__image--smallWidth-mobile'
									: 'product-card-design2-small__image--smallWidth'
							"
						/>
						<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="
									$b.mt
										? 'product-card-design2-small__image--smallWidth-mobile'
										: 'product-card-design2-small__image--smallWidth'
								"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-2 h100',
						{ 'no-stock': outOfStock, 'justify-space-start': btn && forMobileShop },
					]"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="name">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										'line-clamp-2 font-1 header mt-2 px-1',
										alignment == 'left'
											? 'text-start'
											: alignment == 'center'
											? 'text-center'
											: 'text-end',
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>

					<hook zone="attrs&financingTags">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTags">
						<div v-if="$srv('textTags', []).length" :class="$bem('__text-tags-wrapper')">
							<div
								v-if="hasTextTag"
								:class="[
									$bem('__text-tags'),
									'font-0 line-clamp-1 px-1 error rounded',
									{
										'alignment-center': alignment == 'center',
										'alignment-right': alignment == 'right',
									},
								]"
							>
								{{ textTag }}
							</div>
						</div>
					</hook>

					<ProductCard-Tags class="font-0" />
					<v-spacer />

					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text mr-2 font-0']"
						:priceClass="[$bem('__price'), 'price font-3 line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags />

					<div
						v-if="hasBtn"
						:class="[
							'pa-3',
							{
								btnHoverVertical: btnHover && forMobileShop,
								'btnHoverVertical--show': btnHover && hover && forMobileShop,
								z1: product.hasUniqueVariant && !product.info.customizationKey,
							},
						]"
					>
						<hook zone="btn">
							<v-btn
								v-if="buttonText == 'Sin stock' || btnType == 'btn' || btnType == 'btnHover'"
								class="cta w100 rounded-md product-card__buy-btn"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
							<ProductCard-QtyInput v-else />
						</hook>
					</div>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div
		v-else
		:class="[
			'w100 product-card--horizontal',
			{
				'product-card-design2-small-horizontal-wrapper-forBtn': btnHover,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="[
					'd-flex overflow-hidden base product-card py-2',
					$bem('product-card-design2-small-horizontal'),
					{ 'product-card-design2-small-horizontal-forBtn': hasBtn, 'card-border': cardBorder },
				]"
			>
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<hook zone="imageHorizontal" class="h100 p-relative">
						<Media
							:class="$bem('__main-image', 'px-1 h100')"
							:src="mainImage"
							width="100%"
							imgClass="product-card-design2-small-horizontal__image--smallWidth"
						/>

						<div
							v-if="secondImage && hover && imgHover"
							:class="$bem('__second-image-cont', 'px-1 h100')"
						>
							<Media
								:src="secondImage"
								width="100%"
								:class="[
									$bem('__second-image', 'h100'),
									hover ? $bem('__second-image--show') : '',
									'h100',
								]"
								imgClass="product-card-design2-small-horizontal__image--smallWidth"
							/>
						</div>
					</hook>

					<hook zone="offerHorizontal">
						<LimitedTimeOffer
							:product="product"
							textAlign="start"
							class="mx-2 rounded line-clamp-1 font-weight-regular"
							style="width: inherit; padding: 0 4px !important"
						/>
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
				>
					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="nameHorizontal">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										'line-clamp-2 font-2 header mt-1',
										{ 'mt-3': hasBtn },
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[$bem('__brand'), 'font-0 line-clamp-1']"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>

					<hook zone="attrs&financingTagsHorizontal">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTagsHorizontal">
						<div v-if="$srv('textTags', []).length" :class="$bem('__text-tags-wrapper')">
							<div
								v-if="hasTextTag"
								:class="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 error rounded']"
							>
								{{ textTag }}
							</div>
						</div>
					</hook>

					<ProductCard-Tags class="font-0" />

					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="['d-flex align-center line-clamp-1', { 'pb-2': !hasBtn }]"
						:prevPriceClass="['grey--text font-0 mr-1', $bem('__prev-price')]"
						:priceClass="[$bem('__price'), 'price font-4 line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						horizontalCard
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>
					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags :vertical-card="false" />

					<div
						v-if="hasBtn"
						class="py-2"
						:class="{
							btnHoverHorizontal: btnHover && forMobileShop,
							'btnHoverHorizontal--show': (btnHover && showBtnHoverMobile) || (btnHover && hover),
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								v-if="buttonText == 'Sin stock' || btnType == 'btn' || btnType == 'btnHover'"
								class="cta w100 rounded-md product-card__buy-btn"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
							<ProductCard-QtyInput v-else />
						</hook>
					</div>

					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}
.no-stock {
	opacity: 0.4;
}
a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1;
	left: 0;
}

// VERTICAL CARD CLASSES
.product-card-design2-small-wrapper {
	min-height: 270px;
	width: 100%;
	min-width: 100px;

	&-forBtn {
		padding-bottom: 50px;
	}
}

.product-card-design2-small-wrapper-mobile {
	width: 90%;

	&-forBtn {
		width: 90%;
	}
}

.product-card-design2-small {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__event-icon {
		position: absolute;
		top: 0;
		left: 8px;
		z-index: 1;
		width: 25px;
	}

	&__name {
		min-height: 42px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	&__text-tags-wrapper {
		min-height: 23.67px;
	}

	& ::v-deep .product-card__attrs-tags {
		// min-height: 38px;
		// padding: 0 0 8px 0;
	}
}

::v-deep .product-card-design2-small {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.alignment-right {
		float: right;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		border-bottom-left-radius: 4px;
	}

	&__image--smallWidth {
		min-width: 180px;
		min-height: 180px;
		width: 100%;
		height: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	&__image--smallWidth-mobile {
		max-width: 170px;
		max-height: 170px;
		width: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	& .product-card__financing-wrapper {
		min-height: 23.19px;
	}

	& .product-card__rating-wrapper {
		min-height: 31.8px;
	}

	& .product-card__attrs-tags {
		min-height: 38px;
		padding: 0 0 8px 0;
	}

	& .product-card__financing-wrapper {
		min-height: 23.19px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design2-small-horizontal {
	// height: 200px;

	// &-forBtn {
	// 	height: 240px;
	// }
	height: 100%;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__second-image-cont {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 10px;
		z-index: 1;
		width: 25px;
	}

	&__name {
		min-height: 47.59px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	&__text-tags-wrapper {
		min-height: 23.67px;
	}
}

::v-deep div.product-card-design2-small-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__price {
		display: -webkit-box;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
	& .product-card__financing-wrapper {
		min-height: 23.19px;
	}
}
</style>

