<script>
export default {
	inject: ['manager'],
}
</script>

<template>
	<div v-if="manager.hasTextTag">
		<div
			v-for="({ text }, n) in manager.textTags"
			:key="n"
			:class="[
				'product-card__text-tag font-0 line-clamp-1 px-1 error rounded',
				{
					'alignment-center': manager.alignment == 'center',
					'alignment-right mt-1': manager.alignment == 'right',
				},
			]"
		>
			{{ text }}
		</div>
	</div>
</template>

